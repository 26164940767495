import { toggleChatComponent } from 'actions/Setting';
import { apiRequest } from 'app/modules/shared/api/apiRequest';
import { useAuthuser } from 'app/modules/shared/hooks/useAuthuser';
import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ChatComponentAPI } from './ChatComponentAPI';

function ChatComponent({ chatOpenned, toggleChatComponent }) {
    const { company, factory } = useAuthuser();
    const firstRender = useRef(true);
    const [scopeLinesNames, setScopeLinesNames] = useState([]);
    const user = JSON.parse(localStorage.getItem('user_id'));
    const userRoles = user?.roles?.map((r) => r.name);
    const visitorInfo = useMemo(
        () => ({
            'Company Name ': `${user.company}`,
            'Factory Name': `${user.factoryData.name}`,
            'Role List': `${userRoles}`,
            'Main Role': `${user.mainRole.name}`,
            'scope name': `${scopeLinesNames}`,
            'User Language': `${user.lang}`,
            'Scope type': `${
                user.scope.garment
                    ? 'Garment'
                    : user.scope.company.length > 0
                      ? 'Company'
                      : user.scope.factory.length > 0
                        ? 'Factory'
                        : 'production line'
            }`,
        }),
        [
            user.company,
            user.factoryData.name,
            user.mainRole.name,
            user.lang,
            user.scope.garment,
            user.scope.company.length,
            user.scope.factory.length,
            userRoles,
            scopeLinesNames,
        ],
    );

    useEffect(() => {
        apiRequest(ChatComponentAPI.getLinesByScope, company, factory)
            .then(({ data }) => {
                const productionLinesName =
                    data.data.productionLinesAndSections?.map(
                        (line) => line.name,
                    );
                setScopeLinesNames(
                    user.scope.garment
                        ? productionLinesName?.at(0)
                        : productionLinesName,
                );
            })
            .catch((e) => console.error(e));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setScopeLinesNames]);

    useEffect(() => {
        if (
            user &&
            user?.token &&
            firstRender.current &&
            scopeLinesNames.length &&
            import.meta.env.VITE_ZOHO_SALESIQ_KEY
        ) {
            firstRender.current = false;
            window.$zoho = window.$zoho || {};
            window.$zoho.salesiq = window.$zoho.salesiq || {
                widgetcode: import.meta.env.VITE_ZOHO_SALESIQ_KEY,
                values: {
                    name: `${user.firstName} ${user.lastName}`,
                    email: `${user.email}`,
                    language: `${user.lang}`,
                },
                ready: () => {
                    window.$zoho.salesiq.visitor.info(visitorInfo);
                    window.$zoho.salesiq.floatwindow.visible('show');
                },
            };
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'zsiqscript';
            script.defer = true;
            script.src = import.meta.env.VITE_ZOHO_SALESIQ_LINK;
            document.body.appendChild(script);
            const containerElement =
                document.getElementsByClassName('zls-sptwndw')[0];
            const containerChatIcon =
                document.getElementsByClassName('zsiq_floatmain')[0];
            containerElement && containerElement.classList.add('siqanim');
            containerChatIcon && containerChatIcon.classList.remove('d-none');
        }
        return () => {
            const containerElement =
                document.getElementsByClassName('zls-sptwndw')[0];
            const containerChatIcon =
                document.getElementsByClassName('zsiq_floatmain')[0];

            if (containerElement && containerChatIcon) {
                containerChatIcon.classList.add('d-none');
                containerElement.classList.remove('siqanim');
            }
        };
    }, [scopeLinesNames.length, user, visitorInfo]);

    return null;
}
const mapStateToProps = ({ settings }) => {
    const { chatOpenned } = settings;
    return {
        chatOpenned,
    };
};

export default connect(mapStateToProps, {
    toggleChatComponent,
})(ChatComponent);
