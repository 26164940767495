import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthuser } from './modules/shared/hooks/useAuthuser';
import { detectBrowser } from './modules/shared/util/detectUserBrowser';

const GoogleTagManager = () => {
    const { pathname } = useLocation();
    const user = useAuthuser();

    useEffect(() => {
        if (Object.keys(user).length) {
            // Add user data to Tag Manager
            window.dataLayer.push({
                event: 'custom_pageview_eventname',
                browser_os: detectBrowser(),
                company_slug: user?.companyData.slug,
                domain: window.location.host,
                factory_id: user?.factoryData?._id,
                factory_number: user?.factory,
                is_mobile:
                    window?.navigator?.userAgentData?.mobile || 'Unknown',
                platform_type:
                    window?.navigator?.userAgentData?.platform || 'Unknown',
                timestamp: new Date().toISOString(),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                user_email: user?.email,
                user_id: user?._id,
                user_lang: user?.lang,
                user_name: `${user?.firstName} ${user?.lastName}`,
                user_role: user?.mainRole?._id,
                user_type: user?.scope?.garment ? 'GARMENT' : 'FACTORY',
            });
        }
    }, [pathname, user]);

    return null;
};

export default GoogleTagManager;
