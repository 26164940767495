import React from 'react';
import {
    createMetabaseItems,
    getPermissibleNavigationItems,
    navItems,
} from '../Layout/sidebar-menuItems';
import { apiRequest } from 'app/modules/shared/api/apiRequest';
import { RoutesAPI } from './RoutesAPI';
import { faIndustry } from '@fortawesome/free-solid-svg-icons';

const Dashboard2 = React.lazy(() =>
    import('app/modules/AnalyticsAndReports/Dashboard/Dashboard2')
);

export function createMetabaseRoutes(metabaseDashboards) {
    return metabaseDashboards.map(({ permission }) => {
        return {
            path: '/:factoryId/dashboardNew/:dashboardId',
            component: <Dashboard2 />,
            layout: {
                header: true,
                footer: true,
                side: true,
            },
            exact: true,
            auth: true,
            permissions: [permission.name],
        };
    });
}

export function getMetabaseDataFromLocalStoreage() {
    const metabaseStoredData = localStorage.getItem('metabase');
    return metabaseStoredData ? JSON.parse(metabaseStoredData).data : [];
}
function getNavigationItemsWithfactories(
    modifiedNavItems,
    children,
    homepage,
    currentFactoryId,
    currentFactoryName,
    dashboardId
) {
    return children?.length > 1 && currentFactoryId
        ? [
              {
                  type: 'collapseSection',
                  name: `${currentFactoryName}`,
                  icon: faIndustry,
                  children:
                      currentFactoryId &&
                      children
                          ?.filter(
                              (child) =>
                                  child.factory_company_id !== currentFactoryId
                          )
                          .map((child) => {
                              const landingPage = homepage?.includes(
                                  '_metabase'
                              )
                                  ? `dashboardNew/${dashboardId}`
                                  : homepage;
                              return {
                                  type: 'factoryItem',
                                  name: child.name,
                                  icon: faIndustry,
                                  linkTo: `/${child.factory_company_id}/${landingPage}`,
                                  factoryNumber: child.factory_company_id,
                                  factoryData: { ...child },
                              };
                          }),
                  id: 'factoryItem',
              },
              ...modifiedNavItems,
          ]
        : [...modifiedNavItems];
}

export async function getNavigationItems(rawAuthUser, metabaseData) {
    const authUser = rawAuthUser && JSON.parse(rawAuthUser);
    const userPermissions = authUser?.permissions || [];
    const storedMetabaseData = getMetabaseDataFromLocalStoreage();

    const metabaseItems = createMetabaseItems(
        metabaseData.length ? metabaseData : storedMetabaseData
    );

    let sideMenuCategories = localStorage.getItem('sideMenuCategories');
    if (!sideMenuCategories && authUser) {
        await apiRequest(RoutesAPI.getSideMenuCategories)
            .then(({ data }) => {
                sideMenuCategories = JSON.stringify(data.data);
                localStorage.setItem('sideMenuCategories', sideMenuCategories);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const sideMenuGroups = JSON.parse(sideMenuCategories) ?? [];

    const filteredNavItems = navItems.filter((navItem) => {
        return navItem.children
            ? sideMenuGroups.some((group) => group.name === navItem.id)
            : navItem;
    });
    const user = JSON.parse(localStorage.getItem('user_id'));
    const currentFactoryId = `${user?.factory}`;
    const currentFactoryName = user?.factoryData?.name;

    const modifiedNavItems = addDashboardsToGroups(
        metabaseItems,
        filteredNavItems
    );

    const userHomepageRole = authUser?.roles.find(
        (role) => role.homepage === authUser?.homepage
    );
    const mainRole =
        userHomepageRole ?? authUser?.mainRole ?? authUser?.roles[0];
    const dashboard =
        authUser?.dashboards?.find(
            (dashboard) => mainRole.homepage === dashboard.permission.page
        ) || null;

    const newNavigationItems = getNavigationItemsWithfactories(
        modifiedNavItems,
        user?.factories,
        user?.homepage,
        currentFactoryId,
        currentFactoryName,
        dashboard?.id
    );
    return getPermissibleNavigationItems(userPermissions, newNavigationItems);
}

function addDashboardsToGroups(metabaseItems, items) {
    let modifiedNavItems = items.map((navItem) => {
        if (navItem?.children) {
            let navItemChildren = navItem.children.map((child) => {
                if (child.children) {
                    return {
                        ...child,
                        children: [
                            ...child.children,
                            ...metabaseItems.filter(
                                (metabaseItem) =>
                                    metabaseItem.parent === child.id
                            ),
                        ],
                    };
                } else {
                    return child;
                }
            });

            return {
                ...navItem,
                children: [
                    ...navItemChildren,
                    ...metabaseItems.filter(
                        (metabaseItem) => metabaseItem.parent === navItem.id
                    ),
                ],
            };
        } else {
            return navItem;
        }
    });
    return modifiedNavItems;
}
