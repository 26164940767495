import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IntlMessages from '../../../../util/IntlMessages';
import Chip from '@material-ui/core/Chip';

const UserModal = ({ onClose, data }) => {
    return (
        <Dialog open={true} onClose={onClose} maxWidth={'sm'}>
            <DialogTitle id='form-dialog-title'>
                <strong className='d-block text-center'>
                    {data.firstName + ' ' + data.lastName}
                </strong>
            </DialogTitle>
            <DialogContent>
                <UserModalContent data={data} />
            </DialogContent>
        </Dialog>
    );
};

export const UserModalContent = ({ data }) => {
    return (
        <div className='h4'>
            <div className='mb-2'>
                <strong>
                    <IntlMessages id={'shared.role'} />:{' '}
                </strong>
                <span>{data.roles.join(', ')}</span>
            </div>
            {data.scope.productionLines &&
                data.scope.productionLines.length > 0 && (
                    <div className='mb-2'>
                        <strong>
                            <IntlMessages id={'shared.productionLine.plural'} />
                            :{' '}
                        </strong>
                        <span>
                            {data.scope.productionLines.map((line) => (
                                <Chip
                                    key={line}
                                    label={line}
                                    size='small'
                                    className={'mx-1'}
                                />
                            ))}
                        </span>
                    </div>
                )}
            {data.scope.sections && data.scope.sections.length > 0 && (
                <div className='mb-2'>
                    <strong>
                        <IntlMessages id={'shared.productionSection.plural'} />:{' '}
                    </strong>
                    <span>
                        {data.scope.sections.map((section) => (
                            <Chip
                                key={section}
                                label={section}
                                size='small'
                                className={'mx-1'}
                            />
                        ))}
                    </span>
                </div>
            )}
        </div>
    );
};

export default UserModal;
