import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@material-ui/core';
import { userSignOut } from 'actions/Auth';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import LogoutDialog from '../LogoutDialog/LogoutDialog';
import { openLink } from '../../util/openLink';
import { toggleChatComponent } from 'actions/Setting';

class UserInfo extends React.Component {
    state = {
        anchorEl: null,
        openMenu: false,
        userName: '',
        openDialog: false,
        isAdmin: false,
    };

    componentDidMount() {
        const user = JSON.parse(this.props.authUser);

        const firstName = user.firstName || '';
        const lastName = user.lastName || '';
        this.setState({
            userName: `${firstName} ${lastName} `,
            isAdmin: user.scope.garment,
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.authUser !== this.props.authUser) {
            const user = JSON.parse(this.props.authUser);

            const firstName = user.firstName || '';
            const lastName = user.lastName || '';
            this.setState({
                userName: `${firstName} ${lastName} `,
                isAdmin: user.scope.garment,
            });
        }
    };

    handleClick = (event) => {
        this.setState({ openMenu: true, anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ openMenu: false });
    };

    openDialog = () => {
        this.setState({ openDialog: true });
    };

    closeDialog = () => {
        this.setState({ openDialog: false });
    };

    handleNavigation = (type, event) => {
        let url = '';
        switch (type) {
            case 'notifications':
                url = '/settings/notifications';
                break;
            case 'password':
                url = '/settings/changePassword';
                break;
            case 'translation':
                url = '/admin/translation';
                break;
            default:
                break;
        }
        openLink(url, event);
        this.handleClose();
    };

    logout = () => {
        this.handleClose();
        this.props.userSignOut();
        this.props.toggleChatComponent(false);
    };

    render() {
        const { showMenu, adminPage = false } = this.props;
        return (
            <div className='user-profile d-flex flex-row align-items-center'>
                <FontAwesomeIcon className='mx-2 text-light' icon={faUser} />

                <div style={{ color: 'white' }} className='user-detail  ml-1'>
                    <h4
                        className='user-name'
                        onClick={showMenu ? this.handleClick : this.openDialog}
                    >
                        {this.state.userName}
                        {showMenu ? (
                            <i className='zmdi zmdi-caret-down zmdi-hc-fw align-middle' />
                        ) : (
                            <i className='zmdi zmdi-sign-in zmdi-hc-fw mr-2' />
                        )}
                    </h4>
                </div>
                {this.state.openDialog && (
                    <LogoutDialog
                        isOpen={this.state.openDialog}
                        closeDialog={this.closeDialog}
                        handleConfirmation={this.handleClose}
                    />
                )}
                {this.props.showMenu && (
                    <Menu
                        className='user-info'
                        id='simple-menu'
                        anchorEl={this.state.anchorEl}
                        open={this.state.openMenu}
                        onClose={this.handleClose}
                        PaperProps={{
                            style: {
                                minWidth: 120,
                                paddingTop: 0,
                                paddingBottom: 0,
                            },
                        }}
                    >
                        {/* <MenuItem onClick={this.handleClose}>
                            <i className='zmdi zmdi-account zmdi-hc-fw mr-2' />
                            <IntlMessages id='popup.profile' />
                        </MenuItem> */}

                        {adminPage && (
                            <MenuItem onClick={(event) => openLink('/', event)}>
                                <i className='zmdi zmdi-forward zmdi-hc-fw mr-2' />
                                <IntlMessages id='userInfo.menuItems.backToApp' />
                            </MenuItem>
                        )}
                        {this.state.isAdmin && !adminPage && (
                            <MenuItem
                                onClick={(event) =>
                                    this.handleNavigation('translation', event)
                                }
                            >
                                <i className='zmdi zmdi-shield-security zmdi-hc-fw mr-2' />
                                <IntlMessages id='controlPanel.title' />
                            </MenuItem>
                        )}

                        <MenuItem
                            onClick={(event) =>
                                this.handleNavigation('password', event)
                            }
                        >
                            <i className='zmdi zmdi-key zmdi-hc-fw mr-2' />
                            <IntlMessages id='changePassword.title' />
                        </MenuItem>

                        <MenuItem
                            onClick={(event) =>
                                this.handleNavigation('notifications', event)
                            }
                        >
                            <i className='zmdi zmdi-settings zmdi-hc-fw mr-2' />
                            <IntlMessages id='appNotification.title' />
                        </MenuItem>

                        <MenuItem onClick={this.logout}>
                            <i className='zmdi zmdi-sign-in zmdi-hc-fw mr-2' />

                            <IntlMessages id='popup.logout' />
                        </MenuItem>
                    </Menu>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ auth: { authUser } }) => ({
    authUser,
});

export default connect(mapStateToProps, { userSignOut, toggleChatComponent })(
    withRouter(UserInfo)
);
