import { openLink } from './openLink';

const findMatchingObject = (mainRole, dashboards) =>
    dashboards.find(
        (dashboard) => mainRole.homepage === dashboard.permission.page
    ) || null;

export function includesDashMetabase(page) {
    return page.includes('_metabase');
}

export const redirectToLandingPage = (authUser) => {
    if (authUser) {
        const factoryId = authUser.factory;
        const homePage =
            authUser?.homepage ||
            authUser?.mainRole?.homepage ||
            authUser?.roles?.[0]?.homepage;
        const userHomepageRole = authUser.roles.find(
            (role) => role.homepage === authUser?.homepage
        );

        const dashboard = findMatchingObject(
            userHomepageRole ?? authUser?.mainRole ?? authUser.roles[0],
            authUser.dashboards
        );
        let redirectionPage = '/404';

        switch (homePage) {
            case 'device_processes':
            case 'inline_quality_add':
                redirectionPage = `/${factoryId}/s/machines`;
                break;
            case 'cs':
                redirectionPage = `/${factoryId}/stations`;
                break;
            case 'employee_daily_hours_report':
            case 'daily_hours_report':
                redirectionPage = `/${factoryId}/hourlyReport`;
                break;
            case 'styles_daily_hours_report':
                redirectionPage = `/${factoryId}/throughputReport`;
                break;
            case 'live_feed':
                redirectionPage = `/${factoryId}/liveFeed`;
                break;
            case 'style_categories':
                redirectionPage = `/${factoryId}/styleCategories`;
                break;
            case 'lines_efficiency':
                redirectionPage = `/${factoryId}/linesEfficiency`;
                break;
            default:
                if (includesDashMetabase(homePage) && dashboard?.id)
                    redirectionPage = `/${factoryId}/dashboardNew/${dashboard.id}`;
                else redirectionPage = `/${factoryId}/${homePage}`;
        }

        openLink(redirectionPage, { ctrlKey: false });
    }
};
