import MomentUtils from '@date-io/moment';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import 'assets/vendors/style';
import defaultTheme from 'containers/themes/defaultTheme';
import { decode } from 'jsonwebtoken-esm';
import AppLocale from 'lngProvider';
import arLocalTranslations from 'lngProvider/defaultApp-ar.json';
import enLocalTranslations from 'lngProvider/defaultApp-en.json';
import frLocalTranslations from 'lngProvider/defaultApp-fr.json';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { ToastContainer } from 'react-toastify';

import { setSocketId, setUserPermissions, userSignOut } from 'actions/Auth';
import { switchLanguage } from 'actions/Setting';
import RTL from 'util/RTL';
import axiosInstance from './modules/shared/api/axiosInstance';
import devicesAxiosInstance from './modules/shared/api/devicesAxiosInstance';

import { socket } from './modules/shared/socket/socket';

import './modules/shared/styles/theme.scss';

import Router from './modules/Router/Router';
import { urlBase64ToUint8Array } from './modules/shared/util/urlBase64ToUint8Array';

import { getNotifications, processNotification } from 'actions/Notifications';
import { TranslationAPI } from './modules/CompanyManagement/Translations/TranslationAPI';
import {
    apiRequest,
    handlePromiseError,
} from './modules/shared/api/apiRequest';

import { ConfigProvider } from 'antd';

import { registerLicense } from '@syncfusion/ej2-base';
import TagManager from 'react-gtm-module';
import GoogleTagManager from './GoogleTagManager';
import DevtoolCheckbox from './modules/devtools/DevtoolCheckbox';
import { Spinner } from './modules/shared/components/Spinner/Spinner';

//import sentry
import * as Sentry from '@sentry/react';
import { IS_DEPLOYMENT } from 'index';
import { matchPath } from 'react-router-dom';
import routes from './modules/Router/routes';
import { ErrorBoundary } from './modules/shared/error/ErrorBoundary';
import ChatComponent from './ChatComponent/ChatComponent';
import { createBrowserHistory } from 'history/cjs/history.min';
import withCacheValidation from '../CacheValidation';

const history = createBrowserHistory();

class App extends Component {
    state = {
        translations: [],
        tagManagerArgs: {
            gtmId: 'GTM-PV939X56',
        },
    };

    async componentDidMount() {
        registerLicense(import.meta.env.VITE_SYNCFUSION_KEY);
        socket.on('connect', () => this.props.setSocketId(socket.id));

        //gtag
        if (IS_DEPLOYMENT && !window.location.hostname.includes('alexr')) {
            window.addEventListener(
                'load',
                () => {
                    ReactGA.initialize('UA-110457752-3');
                    ReactGA.pageview(
                        window.location.pathname + window.location.search,
                    );
                },
                false,
            );
        }

        // Initialize Google Tag Manager
        TagManager.initialize(this.state.tagManagerArgs);

        if (this.props.authUser) {
            const user = JSON.parse(this.props.authUser);
            const token = user.token;
            const tokenExpiry = decode(token) && decode(token).exp;
            const now = Date.now() / 1000;

            const userId = user._id;

            if (!tokenExpiry || tokenExpiry < now) {
                this.props.userSignOut();
                return;
            }

            this.checkIsTranslationUpdated();

            socket.emit('subscribe_notification', {
                userId,
            });

            const sentryKey = import.meta.env.VITE_SENTRY_KEY;

            if (IS_DEPLOYMENT) {
                Sentry.init({
                    dsn: sentryKey,
                    integrations: [
                        Sentry.replayIntegration(),
                        Sentry.reactRouterV5BrowserTracingIntegration({
                            history,
                            routes,
                            matchPath,
                        }),
                    ],
                    tracesSampleRate: 1.0,
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                    beforeSend: (event, hint) => {
                        const { response } = hint.originalException;
                        if (
                            response?.status &&
                            response.status >= 400 &&
                            response.status < 402
                        ) {
                            return null;
                        }

                        return event;
                    },
                });

                Sentry.setUser({
                    id: user._id,
                    email: user.email,
                    company: user.company,
                    factory: user.factory,
                    token: user.token,
                });
            }

            if ('serviceWorker' in navigator) {
                this.subscribeToPushNotification(userId);
            }

            this.props.getNotifications(this.props.slice);

            socket.on('notifications', (notification) =>
                this.props.processNotification(notification),
            );
        } else {
            this.setState({ isTranslationLoading: false });
        }

        const bodyEl = document.getElementById('body');
        bodyEl.classList.add(
            `${
                this.props.locale.locale === 'ar' ? 'arabicFont' : 'englishFont'
            }`,
        );
        this.handleHTMLDirection();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.locale.locale !== this.props.locale.locale) {
            const bodyEl = document.getElementById('body');

            this.props.locale.locale === 'ar'
                ? bodyEl.classList.remove('englishFont')
                : bodyEl.classList.remove('arabicFont');

            bodyEl.classList.add(
                `${
                    this.props.locale.locale === 'ar'
                        ? 'arabicFont'
                        : 'englishFont'
                }`,
            );
        }
        this.handleHTMLDirection();

        if (prevProps.location !== this.props.location) {
            setTimeout(() => {
                window.onbeforeunload = false;
            }, 0);
        }

        if (
            (this.props.authUser !== prevProps.authUser &&
                this.props.authUser &&
                this.state.translations.length === 0) ||
            prevProps.location.pathname === '/reload'
        ) {
            this.checkIsTranslationUpdated();
        }
    }

    handleHTMLDirection() {
        const html = document.querySelector('html');
        html.setAttribute(
            'dir',
            this.props.locale.locale === 'ar' ? 'rtl' : 'ltr',
        );
    }

    subscribeToPushNotification = async (userId) => {
        //register service worker
        console.log('registering service worker...');
        const register = await navigator.serviceWorker.register('/worker.js', {
            scope: '/',
        });
        console.log('service worker registered...');

        const webPushApiKey =
            'BIspO6pVaT4unZkdPvJGQsf9nH_fHZ8BB5ReRPJsyjXZj_EUrkZKb4bHHIGnXRFLpvmaqMukGfD_o9tEOEigk3c';

        let subscription;

        //register push
        console.log('registering push...');
        try {
            subscription = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(webPushApiKey),
            });
        } catch (error) {
            console.log('failed registering', error);
            return;
        }

        console.log('push registered...');

        //send push notification
        console.log('sending subscription...');
        await axiosInstance.post('/notification/pushSubscribe', {
            subscription,
            userId,
        });

        console.log('subscribed 🚀🚀');
    };

    checkIsTranslationUpdated = () => {
        const user = JSON.parse(this.props.authUser);

        const translations =
            (localStorage.getItem('translations') &&
                JSON.parse(localStorage.getItem('translations'))) ||
            [];

        const translationsVersion = translations.version ?? 0;

        this.setState({ translations });

        apiRequest(
            TranslationAPI.isTranslationUpdated,
            user.company,
            translationsVersion,
        )
            .then(({ data }) => {
                if (!data.data.updated) return;
                apiRequest(TranslationAPI.getTranslations, user.company)
                    .then(({ data }) => {
                        const formulatedTranslations =
                            this.reformulateTranslations(data.data);
                        localStorage.setItem(
                            'translations',
                            JSON.stringify(formulatedTranslations, null, 2),
                        );
                        this.setState(
                            { translations: formulatedTranslations },
                            () => {
                                this.setState({ isTranslationLoading: false });
                            },
                        );
                    })
                    .catch(handlePromiseError);
            })
            .catch(handlePromiseError);
    };

    reformulateTranslations = ({ translations, version }) => {
        const en = {};
        const ar = {};
        const fr = {};
        const enTooltip = {};
        const arTooltip = {};
        const frTooltip = {};
        translations.forEach((item) => {
            en[item.key] = item.value.en;
            ar[item.key] = item.value.ar;
            fr[item.key] = item.value.fr;
            enTooltip[item.key] = item.tooltip.en;
            arTooltip[item.key] = item.tooltip.ar;
            frTooltip[item.key] = item.tooltip.fr;
        });
        return {
            en,
            ar,
            fr,
            enTooltip,
            arTooltip,
            frTooltip,
            version,
        };
    };

    render() {
        const { locale, authUser, isDirectionRTL, toastOn, chatOpenned } =
            this.props;

        let userLang = '';

        if (authUser) {
            const user = JSON.parse(authUser);
            axiosInstance.defaults.headers.token = user.token;
            devicesAxiosInstance.defaults.headers.token = user.token;
            userLang = user.lang.toLowerCase();
        }

        const applyTheme = createTheme(defaultTheme);

        if (isDirectionRTL) {
            applyTheme.direction = 'rtl';

            document.body.classList.add('rtl');
        } else {
            document.body.classList.remove('rtl');
            applyTheme.direction = 'ltr';
        }

        const userTranslations = this.state.translations[userLang];

        const currentAppLocale = AppLocale[locale.locale];

        const localTranslations =
            currentAppLocale?.locale === 'en-US'
                ? enLocalTranslations
                : currentAppLocale?.locale === 'fr-FR'
                  ? frLocalTranslations
                  : arLocalTranslations;

        const isReloading = this.props.location.pathname === '/reload';

        return authUser && !userTranslations && !isReloading ? (
            <div className='app-main w-100 d-flex justify-content-center'>
                <Spinner show={true} />
            </div>
        ) : (
            <MuiThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <IntlProvider
                        locale={currentAppLocale.locale}
                        messages={
                            !authUser ? localTranslations : userTranslations
                        }
                    >
                        <ErrorBoundary>
                            <RTL>
                                <ConfigProvider
                                    direction={isDirectionRTL ? 'rtl' : 'ltr'}
                                >
                                    <div className='app-main'>
                                        <GoogleTagManager />
                                        {authUser && chatOpenned && (
                                            <ChatComponent />
                                        )}
                                        <Router />
                                        {toastOn && (
                                            <ToastContainer
                                                rtl={isDirectionRTL}
                                            />
                                        )}
                                        {(import.meta.env.MODE ===
                                            'development' ||
                                            import.meta.env
                                                .VITE_SHOW_DEV_TOOL) && (
                                            <div>
                                                <DevtoolCheckbox />
                                            </div>
                                        )}
                                    </div>
                                </ConfigProvider>
                            </RTL>
                        </ErrorBoundary>
                    </IntlProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = ({ settings, auth, notifications: { slice } }) => {
    const { sideNavColor, locale, isDirectionRTL, chatOpenned } = settings;
    const { authUser, initURL, toastOn } = auth;
    return {
        sideNavColor,
        locale,
        isDirectionRTL,
        authUser,
        initURL,
        toastOn,
        slice,
        chatOpenned,
    };
};

export default connect(mapStateToProps, {
    switchLanguage,
    userSignOut,
    setSocketId,
    getNotifications,
    processNotification,
    setUserPermissions,
})(withCacheValidation(App));
